<template>
  <!-- begin:: Header -->
  <div
    id="kt_header"
    ref="kt_header"
    class="header"
    v-bind:style="islanding || islogin || isregister?'position:relative !important':'position:fixed !important'"
    v-bind:class="headerClasses"
  >
    <div
      class="container-fluid d-flex align-items-center"
    >
      <!-- begin:: Header Menu -->
      <div
        class="header-menu-wrapper header-menu-wrapper-left"
        ref="kt_header_menu_wrapper"
      >
        <div
          v-if="headerMenuEnabled"
          id="kt_header_menu"
          ref="kt_header_menu"
          class="header-menu header-menu-mobile"
          v-bind:class="headerMenuClasses"
        >
        <div class="header-logo">
          <v-img :src="require('@/assets/images/fastmind.svg')"></v-img>
        </div>
        </div>
      </div>
      <!-- end:: Header Menu -->
     
      <div class="d-flex header-menu-wrapper header-menu-wrapper-right">
         <div class="session-landing" v-if="islanding">
          <router-link :to="{ name: 'login' }">
            <v-btn rounded class="text-capitalize sesstion-button">
              Inicia Sesión
            </v-btn>
          </router-link>
        </div>
        <div v-if="islanding || islogin">
          <router-link :to="{ name: 'register' }">
            <v-btn rounded class='text-capitalize week-free-button'>
              <div class="week-free-icon">
                <img :src="require('@/assets/images/checkmark.svg')">
              </div>
              Prueba una semana gratis
            </v-btn>
          </router-link>
          
          <div class="bank-card-detail"><p>Sin necesidad de dejar datos de tarjeta bancaria</p></div>
        </div>
        <div v-if="isregister">
          <router-link :to="{ name: 'login' }">
            <v-btn rounded class='text-capitalize week-free-button'>
              <div class="week-free-icon">
                <img :src="require('@/assets/images/checkmark.svg')">
              </div>
              Iniciar sesión
            </v-btn>
          </router-link>
          
        </div>
        <div class="d-flex align-items-center" v-if="!isregister && !islanding && !islogin">
          <a class="teacher-help d-flex mr-7 align-items-center" href="https://fastmind.mx">
            <img class="mr-2" :src="require('@/assets/images/support.svg')">
            <p class="mb-0 mt-1">¿Necesitas ayuda de una maestra?</p>
          </a>
          <!-- <span class="material-icons" style="font-size:24px;">menu</span> -->
        </div>
      </div>
    </div>
  </div>
  <!-- end:: Header -->
</template>
<script>
import { mapGetters } from "vuex";
// import KTTopbar from "@/view/layout/header/Topbar.vue";
import KTLayoutHeader from "@/assets/js/layout/base/header.js";
import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";

export default {
  name: "KTHeader",
  components: {
    // KTTopbar
  },
  data() {
    return {
      islanding:false,
      isregister:false,
      islogin:false,
    }
  },
  mounted() {
    // Init Desktop & Mobile Headers
    KTLayoutHeader.init("kt_header", "kt_header_mobile");
    // Init Header Menu
    KTLayoutHeaderMenu.init(
      this.$refs["kt_header_menu"],
      this.$refs["kt_header_menu_wrapper"]
    );
    var pathname = new URL(location.href).pathname;

    if (pathname == '/') {
      this.islanding = true;
      this.isregister = false;
      this.islogin = true;

      if (this.isAuthenticated) {
        this.$router.push({ name: "begin" });
      }

    } else if (pathname.includes('register')) {
      this.islanding = false;
      this.isregister = true;
      this.islogin = false;
      if (this.isAuthenticated) {
        this.$router.push({ name: "begin" });
      }
    } else if(pathname.includes('login')){
      this.islanding = false;
      this.isregister = false;
      this.islogin = true;
      if (this.isAuthenticated) {
        this.$router.push({ name: "begin" });
      }
    } else {
      this.islanding = false;
      this.isregister = false;
      this.islogin = false;
    }
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses", "isAuthenticated"]),

    /**
     * Check if the header menu is enabled
     * @returns {boolean}
     */
    headerMenuEnabled() {
      return !!this.layoutConfig("header.menu.self.display");
    },

    /**
     * Get extra classes for header based on the options
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Get extra classes for header menu based on the options
     * @returns {null|*}
     */
    headerMenuClasses() {
      const classes = this.getClasses("header_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    }
  }
};
</script>
